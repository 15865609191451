















































































import Vue from "vue";
import axios from "axios";

const states = {
  idle: "idle",
  loading: "loading",
  loaded: "loaded",
  failed: "failed",
};

export default Vue.extend({
  name: "ResetPassword",
  data: () => ({
    email: "",
    filledinTOTP: "",
    isFormValid: false,
    state: "idle",
    error: undefined,
    detail: [],
    states,
    token: "",
    headers: "Content-Type=application/x-www-form-urlencoded",
    totpRules: [
      (v: string) =>
        !!v ||
        "De eenmalige code uit de Authenticator-app is verplicht, zorg dat deze op tijd wordt ingevoerd",
      (v: string) =>
        (v && v.length === 6) ||
        "De eenmalige code uit de Authenticator-app bestaat uit 6 cijfers ",
    ],
  }),
  methods: {
    ResetForm() {
      this.$data.email = "";
      this.$data.filledinTOTP = "";
    },
    ResetPassword() {
      this.state = "loading";
      this.error = undefined;
      axios
        .get(`${this.$store.state.APIurl}/user/reset-password`, {
          params: { email: this.$data.email, totp: this.$data.filledinTOTP },
        })
        .then(() => {
          this.state = "loaded";
        })
        .catch((error) => {
          this.detail = error.response;
          this.state = "failed";
          this.error = error;
        });
      this.ResetForm();
    },
  },
});
